<script setup>
    import { ref, onBeforeUnmount, defineProps, inject } from "vue";
    import { app } from "o365-modules";
    import OffCanvas from 'o365.vue.components.OffCanvas.vue';
    import { InjectionKeys } from 'o365-utils';
    import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

    import OnlineButtons from 'o365.pwa.vue.components.navbar.hamburgerMenu.OnlineButtons.vue';
    import Apps from 'o365.pwa.vue.components.navbar.hamburgerMenu.Apps.vue';

    const { pwaStoreKey } = InjectionKeys;

    const { state: offlineState, eventEmitter, checkForAppUpdate } = inject(pwaStoreKey);

    const props = defineProps({
        dataObject: {
            type: Object,
            required: false,
            default: null,
        }
    });

    const appState = ref(null);
    const pwaState = ref(null);
    const initialized = ref(false);
    const offCanvasRef = ref(null);

    const toggleOffCanvas = () => {
        if (offCanvasRef.value) {
            offCanvasRef.value.show();
        }
    }

    const getAppFromIndexedDB = async (appId) => {
        appState.value = await IndexedDBHandler.getApp(appId);
        pwaState.value = await appState.value?.pwaState ?? null;
    }

    const checkForAppUpdates = async (appId) => {
        await checkForAppUpdate(appId);
        await getAppFromIndexedDB(appId);
    }

    const initAsync = async () => {
        await offlineState.appInitializedCompleter.promise;
        initialized.value = true;

        eventEmitter.on('appInstalled', checkForAppUpdates);
        eventEmitter.on('appUpdated', checkForAppUpdates);

        await getAppFromIndexedDB(app.id);
    };

    initAsync();

    onBeforeUnmount(() => {
        eventEmitter.off('appInstalled', checkForAppUpdates);
        eventEmitter.off('appUpdated', checkForAppUpdates);
    });
</script>

<template>
    <button class="btn btn-link text-white fs-5" @click="toggleOffCanvas">
        <i class="bi bi-list align-self-center"></i>
    </button>

    <OffCanvas name="canvas" ref="offCanvasRef" position="left">
        <div class="offcanvas-header border-b border">
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body bg-light">
            <OnlineButtons></OnlineButtons>

            <Apps></Apps>
        </div>
    </OffCanvas>
</template>